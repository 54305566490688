import { defineStore } from 'pinia'
import { GET, POST, PUT, DELETE } from '../../utils/api'
import { useAuthStore } from '../auth'
import { toast } from 'vue-sonner'

export const useMenuOptionGroupsStore = defineStore('menuOptionGroups', {
  state: () => ({
    optionGroups: [],
    optionGroupTypeOptions: [
      { value: 'Select', label: 'Select', icon: 'fas fa-fw fa-circle-check' },
      { value: 'Addons', label: 'Addons', icon: 'fas fa-fw fa-square-check' },
      { value: 'Glasses', label: 'Glasses', icon: 'fas fa-fw fa-champagne-glasses' },
    ],
  }),

  getters: {
    optionGroupsOfCurrentRestaurant() {
      const authStore = useAuthStore()
      return this.optionGroups
        .filter(x => x.restaurant_id === authStore.restaurantId)
        .sort((a, b) => a.id - b.id)
    },
    optionGroupsMap() {
      return new Map(this.optionGroupsOfCurrentRestaurant.map(element => [element.id, element]))
    },
  },

  actions: {
    async updateOptionItemsOrder(payload) {
      const optionGroup = this.optionGroups.find(optionGroup => optionGroup.id === payload.id)
      if (optionGroup) {
        optionGroup.items = payload.orderedArray.map(optionItemObject => optionItemObject.id)
      }

      const optionItems = [{
        [payload.id]: payload.orderedArray.map(optionItemObject => ({
          id: optionItemObject.id,
        })),
      }]

      const response = await PUT('accounts/:accountId/menu/options/items', { options: optionItems })
      toast.success('Options order updated')
      return response
    },

    async newOptionGroup(optionGroup) {
      const response = await POST('accounts/:accountId/menu/options', optionGroup)
      toast.success(`Option Group ${optionGroup.name} Created`)
      this.optionGroups.push(response.option)
      return response
    },

    async updateOptionGroup(optionGroupArray) {
      const response = await PUT('accounts/:accountId/menu/options', { options: optionGroupArray })
      const optionGroupNames = optionGroupArray.map(optionGroup => this.optionGroupsMap.get(optionGroup.id)?.name).join(', ')
      const notificationTitle = optionGroupArray.length > 1 ? `Option Groups ${optionGroupNames} updated` : `Option Group ${optionGroupNames} updated`
      toast.success(notificationTitle)
      optionGroupArray.forEach(optionGroup => this.updateOptionGroupInStore(optionGroup))
      return response
    },

    async deleteOptionGroup(optionGroupId) {
      const response = await DELETE(`accounts/:accountId/menu/options/${optionGroupId}`)
      const optionGroupName = this.optionGroupsMap.get(optionGroupId)?.name
      toast.success(`Option Group ${optionGroupName} Deleted`)
      this.optionGroups = this.optionGroups.filter(option_group => option_group.id !== optionGroupId)
      return response
    },

    updateOptionGroupInStore(option_group) {
      const index = this.optionGroups.findIndex(x => x.id === option_group.id)
      if (index !== -1) {
        Object.keys(option_group).forEach(prop => {
          if (prop !== 'overrides') {
            this.optionGroups[index][prop] = option_group[prop]
          }
        })

        // Handle overrides update
        if (option_group.overrides && option_group.overrides.for_item_id) {
          const forItemId = option_group.overrides.for_item_id
          const existingOverrides = this.optionGroups[index]['overrides'] || []
          const newOverrides = option_group.overrides.overrides

          const updatedOverrides = existingOverrides.filter(o => o.item_id !== forItemId)

          newOverrides.forEach(newOverride => {
            if (newOverride.price !== null) {
              updatedOverrides.push({
                item_id: forItemId,
                option_group_id: option_group.id,
                option_item_id: newOverride.option_item_id,
                price: newOverride.price,
              })
            }
          })

          this.optionGroups[index].overrides = updatedOverrides
        }
      }
    },
  },
})
