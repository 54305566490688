<template>
  <div :class="containerClass" v-if="contentIsVisible">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useAuthStore } from '@/stores/auth'

export default defineComponent({
  name: 'Only',

  props: {
    admins: Boolean,
    nonAdmins: Boolean,
    if: {
      type: [String, Boolean],
      default: undefined,
    },
    role: String,
  },

  setup(props) {
    const authStore = useAuthStore()

    const isAdmin = computed(() => authStore.isAdmin)
    const currentRole = computed(() => authStore.currentRole)

    const containerClass = computed(() => {
      if (props.admins) {
        return 'admins'
      }
      if (props.nonAdmins) {
        return 'non-admins'
      }
      if (props.role) {
        return 'role-' + props.role
      }
      return ''
    })

    const contentIsVisible = computed(() => {
      if (props.if !== undefined) {
        if (typeof props.if === 'string') {
          if (!authStore[props.if]) {
            return false
          }
        } else {
          if (!props.if) {
            return false
          }
        }
      }

      if (props.admins) {
        return isAdmin.value
      }
      if (props.nonAdmins) {
        return !isAdmin.value
      }
      if (props.role) {
        return props.role === currentRole.value
      }

      return props.if !== undefined
    })

    return {
      containerClass,
      contentIsVisible,
    }
  },
})
</script>

<style lang="scss">
.admins {
}
.non-admins {
}
.role-admin {
}
.role-owner {
}
.role-manager {
}
.role-employee {
}
</style>
