<template>
  <TooltipProvider>
    <Tooltip v-bind="$attrs" :delayDuration="delay">
      <TooltipTrigger :class="cn($attrs.class)" asChild>
        <span>
          <slot name="icon" :color="color" :size="size">
            <component :is="resolvedIcon" :class="iconClasses" :style="{ color }" />
          </slot>
        </span>
      </TooltipTrigger>
      <TooltipContent class="max-w-[450px] text-left bg-white text-gray-900 border border-gray-200 shadow-md rounded-md py-2 px-3 text-sm">
        <slot></slot>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
</template>

<script setup>
import { computed } from 'vue'
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from '@/components/ui/tooltip'
import { Info, AlertTriangle, Clock, ExternalLink, CircleHelp } from 'lucide-vue-next'
import { cn } from '@/lib/utils'

const props = defineProps({
  icon: {
    type: String,
    default: 'info',
    validator: (value) => ['info', 'help', 'warning', 'clock', 'external-link'].includes(value),
  },
  color: {
    type: String,
    default: '#b8bbd2',
  },
  size: {
    type: String,
    default: '4',
  },
  delay: {
    type: Number,
    default: 100, // 100 feels fast enough for it to still feel instant, but slow enough that it won't trigger the tooltip when you're moving the mouse quickly over it with no intention of seeing the tooltip
  },
  iconClass: {
    type: String,
    default: '',
  },
})

const iconMap = {
  info: Info,
  warning: AlertTriangle,
  clock: Clock,
  'external-link': ExternalLink,
  help: CircleHelp,
  question: CircleHelp,
}

const resolvedIcon = computed(() => iconMap[props.icon] || InfoIcon)

const iconClasses = computed(() => cn(`w-${props.size} h-${props.size}`, props.iconClass))
</script>