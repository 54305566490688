<template>
  <div class="flex h-screen bg-background">
    <SideBar
      v-model:isOpen="sidebarOpen"
      v-model:isMinimized="sidebarMinimized"
      :logo="logo"
      :class="{ '-translate-x-full md:translate-x-0': !sidebarOpen }"
      class="transition-all duration-300 ease-in-out"
    >
      <template #default="{ isHovered, isMinimized }">
        <sidebar-item
          v-for="(link, index) in filteredSidebarLinks"
          :key="link.name + index"
          :link="link"
          :is-minimized="isMinimized"
          :is-hovered="isHovered"
        />
        <sidebar-item
          v-for="(link, index) in filteredAdminLinks"
          :key="link.name + index"
          :link="link"
          :is-minimized="isMinimized"
          :is-hovered="isHovered"
        />
      </template>
    </SideBar>
    <div
      :class="[
        'flex-1 flex flex-col overflow-hidden transition-all duration-300 ease-in-out',
        { 'md:ml-52': !sidebarMinimized && sidebarOpen },
        { 'md:ml-16': sidebarMinimized && sidebarOpen }
      ]"
    >
      <div 
        :class="['flex-1 overflow-y-auto overflow-x-hidden bg-background']"
        ref="mainContent" 
        @scroll="handleMainScroll"
      >
        <Navbar 
          ref="navbar" 
          @toggle-sidebar="toggleSidebar" 
          :is-navbar-visible="isNavbarVisible"
          :navbar-height="navbarHeight"
          class="sticky top-0 z-30"
        />
        <div v-if="layoutMounted && currentAccount" class="main-content-body">
          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
        <content-footer v-if="!$route.meta.hideFooter" class="mt-auto"></content-footer>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, provide, computed, watch, onMounted, onUnmounted } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useRestaurantsStore } from '@/stores/restaurants'
import { useAccountsStore } from '@/stores/accounts'
import { useRoute } from 'vue-router'
import SideBar from '@/components/SidebarPlugin/SideBar.vue'
import SidebarItem from '@/components/SidebarPlugin/SidebarItem.vue'
import Navbar from './Navbar.vue'
import ContentFooter from './Footer.vue'
import { useStickyHeaders } from '@/composables/useStickyHeaders'
import { LayoutDashboard, PieChart, ClipboardList, CreditCard, NotebookText, Package, QrCode, Tablet, MonitorSmartphone, Settings, HandCoins, HelpCircle, ClipboardCheck, Handshake, ContactRound, Twitter, Landmark } from 'lucide-vue-next'
import { faHubspot, faStripe } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const authStore = useAuthStore()
const restaurantsStore = useRestaurantsStore()
const accountsStore = useAccountsStore()
const route = useRoute()

const sidebarOpen = ref(window.innerWidth >= 768)
const sidebarMinimized = ref(false)
const layoutMounted = ref(true)
const logo = '/img/brand/orderli-orange.png'

const isAdmin = computed(() => authStore.isAdmin)
const currentRole = computed(() => authStore.currentRole)
const currentAccount = computed(() => accountsStore.currentAccount)
const currentRestaurant = computed(() => restaurantsStore.currentRestaurant)

const sidebarLinks = [
  { name: 'Dashboard', icon: LayoutDashboard, path: '/dashboard' },
  { name: 'Analytics', icon: PieChart, path: '/analytics' },
  { name: 'Orders', icon: ClipboardList, path: '/orders' },
  { name: 'Payments', icon: CreditCard, path: '/payments', disabled: true },
  { 
    name: 'Menu', 
    icon: NotebookText, 
    path: '', 
    children: [
      { name: 'Menu editor', path: '/menu/menus' },
      { name: 'Cards', path: '/menu/cards' },
      { name: 'Categories', path: '/menu/categories' },
      { name: 'Products', path: '/menu/items' },
      { name: 'Option Groups', path: '/menu/modifiers' },
      { name: 'Recommendations', path: '/menu/recommendations' }
    ]
  },
  { name: 'Inventory', icon: Package, path: '/inventory' },
  { name: 'QR codes', icon: QrCode, path: '/qrs' },
  { name: 'Devices', icon: Tablet, path: '/devices' },
  // OLD CODE COMMENT: { name: 'Devices', icon: MonitorSmartphone, path: '/devices' }, Switch to this icon in the future when it's more than just tablets
  { name: 'Settings', icon: Settings, path: '/settings' },
  { name: 'My tips', icon: HandCoins, path: '/tips' },
  { name: 'Help', icon: HelpCircle, path: '/support' },
  { name: 'Partner portal', icon: Handshake, path: '/reseller' },
  { name: 'Admin', icon: ContactRound, path: '/admin' },
  { name: 'Activity Log', icon: ClipboardCheck, path: '/activity-log' }
]

const adminLinks = computed(() => [
  { 
    name: 'Hubspot', 
    icon: { icon: faHubspot, component: FontAwesomeIcon }, 
    path: currentRestaurant?.hubspot_company_id 
      ? `https://app.hubspot.com/contacts/8545281/company/${currentRestaurant.hubspot_company_id}/`
      : '/settings',
    disabled: !currentRestaurant?.hubspot_company_id,
    external: true
  },
  { 
    name: 'Moneybird', 
    icon: Twitter, 
    path: currentRestaurant?.moneybird_contact_id
      ? `https://moneybird.com/273305291622188976/contacts/${currentRestaurant.moneybird_contact_id}/`
      : '/settings#account',
    disabled: !currentRestaurant?.moneybird_contact_id,
    external: true
  },
  { 
    name: 'Stripe', 
    icon: { icon: faStripe, component: FontAwesomeIcon }, 
    path: currentRestaurant?.stripe_subscription_id
      ? `https://dashboard.stripe.com/subscriptions/${currentRestaurant.stripe_subscription_id}/`
      : '/settings',
    disabled: !currentRestaurant?.stripe_subscription_id,
    external: true
  },
  { 
    name: 'Payaut', 
    icon: Landmark, 
    path: currentRestaurant?.payaut_account_holder_id
      ? `https://dashboard.payaut.com/sellers/${currentRestaurant.payaut_account_holder_id}/balance`
      : '/settings#payments',
    disabled: !currentRestaurant?.payaut_account_holder_id,
    external: true
  },
])

const filteredSidebarLinks = computed(() => {
  return sidebarLinks.filter(link => {
    if (currentRole.value === 'employee') {
      return ['Dashboard', 'My tips', 'Help'].includes(link.name)
    }
    if (currentRole.value === 'manager') {
      return !['Partner portal', 'Admin', 'Activity Log'].includes(link.name)
    }
    return true
  })
})

const filteredAdminLinks = computed(() => {
  return isAdmin.value ? adminLinks.value : []
})

const toggleSidebar = () => {
  if (window.innerWidth < 768) {
    sidebarOpen.value = !sidebarOpen.value
  } else {
    sidebarMinimized.value = !sidebarMinimized.value
  }
}

const closeSidebarOnMobile = () => {
  if (window.innerWidth < 768) {
    sidebarOpen.value = false
  }
}

provide('toggleSidebar', toggleSidebar)

watch(route, () => {
  closeSidebarOnMobile()
})

const mainContent = ref(null)

// Initialize sticky headers
const navbarHeight = ref(64)
const { isNavbarVisible, headerTop, scrollY } = useStickyHeaders(navbarHeight.value, mainContent)

provide('mainScrollContainer', mainContent)
provide('isNavbarVisible', isNavbarVisible)
provide('navbarHeight', navbarHeight)
provide('headerTop', headerTop)
provide('scrollY', scrollY)

// Handle scroll for main content if needed
const handleMainScroll = () => {
  // Additional scroll handling if necessary
}

// Update CSS custom property when navbarHeight changes
watch(navbarHeight, (newValue) => {
  document.documentElement.style.setProperty('--navbar-height', `${newValue}px`);
});

onMounted(() => {
  // Set initial value for CSS custom property
  document.documentElement.style.setProperty('--navbar-height', `${navbarHeight.value}px`);
  // Additional onMounted logic if necessary
  reloadAnalytics()
})

onUnmounted(() => {
  // Additional cleanup if necessary
})

function reloadAnalytics() {
  // Implement your data reloading logic here
}
</script>

<style scoped>
/* We apply a before class to the sticky header (below the navbar), 
   because if you scroll fast up/down the 300ms transition of the navbar showing/hiding 
   doesn't sync up with the 300ms transition of the header moving up/down 
   If you want to see the effect of this, try setting background color to green instead of the var(--sticky-header-bg)
   */
.main-content-body :deep(.sticky-header::before) {
  content: '';
  position: absolute;
  top: calc(var(--navbar-height, 64px) * -1);
  width: 100%;
  height: var(--navbar-height, 64px);
  background-color: hsl(var(--background, 0 0 100%));
  z-index: 10;
}
</style>
