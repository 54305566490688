import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import NProgress from 'nprogress';
import { getIdsFromQueryParams } from '@/plugins/processQueryParams';

// Import your components here
import DashboardLayout from '../views/Starter/Layout.vue';
import Starter from '../views/Pages/Dashboard.vue';
import AuthLayout from "@/views/Pages/AuthLayout";
import NotFound from "@/views/GeneralViews/NotFoundPage";
import { storeToRefs } from 'pinia';
import { watch } from 'vue';

// Pages
const Login = () => import('@/views/Pages/Login.vue');
const ForgotPassword = () => import('@/views/Pages/ForgotPassword.vue');
const Register = () => import('@/views/Pages/Register.vue');
const ConfirmEmail = () => import('@/views/Pages/ConfirmEmail.vue');
const ResetPassword = () => import('@/views/Pages/ResetPassword.vue');
const Join = () => import('@/views/Pages/Join.vue');
const Onboarding = () => import('@/views/Pages/Onboarding/Onboarding.vue');

// Products pages
const ItemsOverview = () => import('@/views/Products/ProductsOverview.vue');
const CategoriesOverview = () => import('@/views/Products/CategoriesOverview.vue');
const CardsOverview = () => import('@/views/Products/CardsOverview.vue');
const MenusOverview = () => import('@/views/Products/MenusOverview.vue');
const OptionsOverview = () => import('@/views/Products/OptionGroupsOverview.vue');
const RecommendationsOverview = () => import('@/views/Products/RecommendationsOverview.vue');

// Other dashboard pages
const Analytics = () => import('@/views/Pages/Analytics.vue');
const MenuItemAnalytics = () => import('@/views/Pages/MenuItemAnalytics.vue');
const Inventory = () => import('@/views/Pages/Inventory.vue');
const Orders = () => import('@/views/Pages/Orders.vue');
const Payments = () => import('@/views/Pages/Payments.vue');
const SalesAreas = () => import('@/views/Pages/SalesAreas.vue');
const Devices = () => import('@/views/Devices/Devices.vue');
const ActivityLog = () => import('@/views/Pages/ActivityLog.vue');
const Settings = () => import('@/views/Settings/Settings.vue');
const Support = () => import('@/views/Pages/Support.vue');
const TippingDashboard = () => import('@/views/Pages/TippingDashboard.vue');

//Admin specific pages
const Admin = () => import('@/views/Pages/Admin.vue');
const Actions = () => import('@/views/Admin/AdminActions.vue');
const Customers = () => import('@/views/Admin/CustomersOverview.vue');
const AdminAnalytics = () => import('@/views/Admin/AdminAnalytics.vue');
const Resellers = () => import('@/views/Admin/ResellersOverview.vue');
const SalesBonuses = () => import('@/views/Admin/SalesBonuses.vue');
const Subscriptions = () => import('@/views/Admin/ManageSubscriptions.vue');
const MethodPrices = () => import('@/views/Admin/ManageMethodPrices.vue');
const PSPSettings = () => import('@/views/Admin/PSPSettings.vue');
const QRPrinter = () => import('@/views/Admin/QRPrinter.vue');
const PosRequestLogger = () => import('@/views/Admin/PosRequestLogger.vue')
const FeatureFlags = () => import('@/views/Admin/FeatureFlags.vue')

//Reseller specific pages
const Reseller = () => import('@/views/Resellers/ResellerAdmin.vue');

//Redirect pages
const MandateCompletion = () => import('@/views/Settings/MandateCompletion.vue')
const OAuth = () => import('@/views/Settings/OAuth.vue')

// OLD CODE COMMENT: const User = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/UserProfile.vue');
// OLD CODE COMMENT: const TimeLine = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/TimeLinePage.vue');
// OLD CODE COMMENT: const Home = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Home.vue');
// OLD CODE COMMENT: const Lock = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Lock.vue');

let authPages = {
  path: '/', component: AuthLayout, name: 'Authentication', meta: { hideOnAuth: true }, children: [ // TODO remove meta?
    // { path: '/home', name: 'Home', component: Home, meta: { noBodyBackground: true } },
    { path: '/login', name: 'Login', component: Login },
    { path: '/forgot-password', name: 'Forgot Password', component: ForgotPassword },
    { path: '/register', name: 'Register', component: Register },
    { path: '/confirm/:id', name: 'Confirm Email', component: ConfirmEmail },
    { path: '/verify/:id', name: 'Verify Email', component: ConfirmEmail },
    { path: '/reset/:id', name: 'Reset Password', component: ResetPassword },
    { path: '/join/:id', name: 'Join Orderli', component: Join },
    // { path: '/user', name: 'User', component: User },
    // { path: '/timeline', name: 'TimeLine', component: TimeLine }
  ]
};

let onboardingPages = {
  path: '/onboarding', component: Onboarding, name: 'Onboarding', meta: { requiresUncompletedOnboarding: true } // TODO remove meta?
};

let productsMenu = {
  path: '/menu', component: DashboardLayout, redirect: '/menu/menus', name: 'Menu', meta: { requiresAuth: true }, children: [
    { path: 'menus', name: 'Menu Editor', component: MenusOverview },
    { path: 'cards', name: 'Cards', component: CardsOverview },
    { path: 'categories', name: 'Categories', component: CategoriesOverview },
    { path: 'items', name: 'Items', component: ItemsOverview },
    { path: 'modifiers', name: 'Option Groups', component: OptionsOverview },
    { path: 'recommendations', name: 'Recommendations', component: RecommendationsOverview }
  ]
};

const router = createRouter({
  history: createWebHistory(),
  routes: [
    productsMenu,
    { path: '/', name: 'home', meta: { requiresAuth: true }, redirect: '/dashboard', component: DashboardLayout, children: [
        { path: 'dashboard', name: 'dashboard', components: { default: Starter } },
        { path: '/analytics', name: 'analytics', component: Analytics },
        { path: '/analytics/item/:id', name: 'Menu item analytics', component: MenuItemAnalytics, props: route => ({startDate: route.query.startDate, endDate: route.query.endDate}) },
        { path: '/orders', name: 'orders', component: Orders },
        { path: '/payments', name: 'payments', component: Payments },
        { path: '/qrs', name: 'QR Codes', component: SalesAreas },
        { path: '/inventory', name: 'inventory', component: Inventory },
        { path: '/devices', name: 'devices', component: Devices },
        // { path: '/payments', name: 'payments', component: Payments },
        { path: '/settings/mandate-completion', name: 'Mandate completion', component: MandateCompletion },
        { path: '/oauth/:service', name: 'OAuth completion', component: OAuth },
        { path: '/activity-log', name: 'Activity Log', component: ActivityLog },
        { path: '/settings', name: 'settings', component: Settings },
        { path: '/support', name: 'Support', component: Support },
        { path: '/tips', name: 'My tips', component: TippingDashboard },
        { path: '/reseller', name: 'Partner Portal', component: Reseller },
        { path: '/admin', component: Admin, meta: {adminOnly: true}, children: [
            { path: '', name: 'admin', component: Actions },
            { path: 'customers', name: 'Admin Customers', component: Customers },
            { path: 'analytics', name: 'Admin Analytics', component: AdminAnalytics },
            { path: 'resellers', name: 'Admin Resellers', component: Resellers },
            { path: 'sales-bonuses', name: 'Admin Sales Bonuses', component: SalesBonuses },
            { path: 'subscriptions', name: 'Admin Subscriptions', component: Subscriptions },
            { path: 'method-prices', name: 'Admin Method Prices', component: MethodPrices },
            { path: 'psp-settings', name: 'Admin PSP Settings', component: PSPSettings },
            { path: 'qr-printer', name: 'Admin QR Printer', component: QRPrinter },
            { path: 'pos-request-logger', name: 'Admin POS Request Logger', component: PosRequestLogger },
            { path: 'feature-flags', name: 'Admin Feature Flags', component: FeatureFlags },
          ]
        }
      ]
    },
    authPages,
    onboardingPages,
    {
      path: '/reports/settlement/:id',
      name: 'Settlement Report',
      beforeEnter: (to, from, next) => {
        next({ path: '/settings', hash: '#payments', replace: true });
      }
    },
    { path: '/:pathMatch(.*)*', name: 'Not Found', component: NotFound }
  ],
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) return savedPosition;
    if (to.hash) return { selector: to.hash };
    return { x: 0, y: 0 };
  }
});

async function checkOnboarding(next, nextPath) {
  const authStore = useAuthStore();
  const onboardingPath = nextPath ? undefined : '/onboarding'; //set onboardingPath to null if nextPath exists, because it is only used by onboarding pages

  try {
    await authStore.checkOnboardingStatus();
    const onboardingCompleted = authStore.onboardingCompleted;
    const isAdmin = authStore.isAdmin;

    if (onboardingCompleted || isAdmin) {
      next(nextPath);
    } else if (onboardingCompleted === false) {
      next(onboardingPath);
    } else {
      // Onboarding status is undefined, allow navigation but component will handle display
      next();
    }
  } catch (error) {
    console.error('Error checking onboarding status:', error);
    // In case of error, allow navigation but component will handle display
    next();
  }
}

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  const authStore = useAuthStore();
  const isLoggedIn = authStore.isLoggedIn;
  const isAdmin = authStore.isAdmin;

  // Skip routing checks if VITE_APP_SKIP_ROUTING is set (for development purposes)
  if (import.meta.env.VITE_APP_SKIP_ROUTING) next();

  const routeRequiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const routeHidesOnAuth = to.matched.some(record => record.meta.hideOnAuth);
  const onboardingRoute = to.matched.some(record => record.meta.requiresUncompletedOnboarding);
  const adminOnlyRoute = to.matched.some(record => record.meta.adminOnly);

  try {
    if (routeRequiresAuth) {
      if (!isLoggedIn) {
        
      // User had no jwt token on load, so they're not logged in
      // User cannot access authenticated pages without being logged in, redirect to login
      next({ path: '/login', query: { nextUrl: to.fullPath + window.location.search } });
      } else {
        await checkOnboarding(next);
      }
    } else if (routeHidesOnAuth) {
      //Logic to prevent user accessing authentication pages (login, register, join, etc.) when already logged in
      if (!isLoggedIn) { next(); } 
      else {
        // Determine which URL the user should be redirected to when already logged in
        let nextUrl = to.query.nextUrl || '/';
        let decodedNextUrl = decodeURIComponent(nextUrl);
        let newQueryPath = getIdsFromQueryParams(new URL(decodedNextUrl, window.location.origin).searchParams);
        
        if (newQueryPath.hasIds) {
          await router.replace({ 
            path: new URL(decodedNextUrl, window.location.origin).pathname, 
            query: newQueryPath.query, 
            hash: new URL(decodedNextUrl, window.location.origin).hash 
          });
        }
        
        next({ 
          path: new URL(decodedNextUrl, window.location.origin).pathname, 
          query: newQueryPath.query, 
          hash: new URL(decodedNextUrl, window.location.origin).hash 
        });
      }
    } else if (onboardingRoute) {
      //Logic to prevent user accessing onboarding pages without being logged in
      if (!isLoggedIn) { next('/login'); } 
      else { await checkOnboarding(next, '/'); }
    } else if (adminOnlyRoute) {
      //Logic to prevent user accessing admin pages without being admin
      next(isAdmin ? undefined : '/');
    } else {
      next();
    }
  } catch (error) {
    console.error('Error in router guard:', error);
    next('/error');
  } finally {
    NProgress.done();
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;