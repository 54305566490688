import { defineStore } from 'pinia'
import { GET, POST, PUT, DELETE } from '../../utils/api'
import { toast } from 'vue-sonner'
import { useAuthStore } from '../auth'

export const useMenuCardsStore = defineStore('menuCards', {
  state: () => ({
    cards: [],
  }),

  getters: {
    cardsOfCurrentRestaurant() {
      const authStore = useAuthStore()
      return this.cards
        .filter((x) => x.restaurant_id === authStore.restaurantId)
        .sort((a, b) => a.id - b.id)
    },
    cardsMap() {
      return new Map(
        this.cardsOfCurrentRestaurant.map((element) => [element.id, element])
      )
    },
  },

  actions: {
    async updateCategoriesOrder(payload) {
      const card = this.cards.find((card) => card.id === payload.id)
      if (card) {
        card.categories = payload.orderedArray.map((categoryObject) => categoryObject.id)
      }

      const cards = [{
        [payload.id]: payload.orderedArray.map((categoryObject) => ({
          id: categoryObject.id,
        })),
      }]

      try {
        const response = await PUT('accounts/:accountId/menu/cards/categories', { cards: cards })
        toast.success('Categories order updated')
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async newCard(card) {
      try {
        const response = await POST('accounts/:accountId/menu/cards', card)
        toast.success(`Card ${card.name} Created`)
        this.cards.push(response.card)
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async updateCard(cardArray) {
      try {
        const response = await PUT('accounts/:accountId/menu/cards', { cards: cardArray })
        const cardNames = cardArray.map(card => this.cardsMap.get(card.id)?.name).join(', ')
        const notificationTitle = cardArray.length > 1 ? `Cards ${cardNames} updated` : `Card ${cardNames} updated`
        toast.success(notificationTitle)
        
        cardArray.forEach(updatedCard => {
          const index = this.cards.findIndex(card => card.id === updatedCard.id)
          if (index !== -1) {
            Object.keys(updatedCard).forEach(prop => {
              this.cards[index][prop] = updatedCard[prop]
            })
          }
        })
        
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async deleteCard(cardId) {
      try {
        const response = await DELETE(`accounts/:accountId/menu/cards/${cardId}`)
        const cardName = this.cardsMap.get(cardId)?.name
        toast.success(`Card ${cardName} Deleted`)
        // TODO SHOULD ALSO BE DELETED FROM MENU STORE, BUT MAYBE MENU STORE SHOULD NOT HAVE CARDS STORED AT ALL??
        const index = this.cards.findIndex(card => card.id === cardId)
        if (index !== -1) {
          this.cards.splice(index, 1)
        }
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },
  },
})
