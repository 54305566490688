import { defineStore } from 'pinia'
import { GET, POST, PUT, DELETE } from '../utils/api'

export const useBillingStore = defineStore('billing', {
  state: () => ({
    billing: {
      loading: false,
      loaded: false,
      account: null,
      restaurants: {}
    },
    restaurantBilling: {},
    accountInvoices: [],
    accountInvoicesCallComplete: null,
    subscriptions: [],
    credits: null,
    subscriptionFeatures: [
      {value: 'multiple_locations', label: 'Multiple locations'},
      {value: 'unlimited_tablets', label: 'Unlimited Tablets'},
      {value: 'inventory_management', label: 'Inventory Management'},
      {value: 'pos_integration', label: 'Pos Integration'},
      {value: 'receipt_printer', label: 'Receipt Printer'},
      {value: 'up_selling', label: 'Up-Selling'},
      {value: 'cross_selling', label: 'Cross-Selling'},
      {value: 'payment_required', label: 'Payment Required'},
      {value: 'custom_colors', label: 'Custom Colors'},
      {value: 'thuis', label: 'Thuis'},
      {value: 'standard_support', label: 'Standard Support'},
      {value: 'priority_support', label: 'Priority Support'},
      {value: 'advanced_branding', label: 'Advanced Branding'},
      {value: 'custom_domain', label: 'Custom domain'},
      {value: 'auto_import', label: 'Auto import'},
      {value: 'lightspeed_receipt_linking', label: 'Lightspeed L receipt stacking'}
    ],
  }),

  getters: {
    billingCompleted: (state) => {
      return true;
      //TODO fix below later
      // let status = state.auth.onboarding.subscriptionStatus;
      // let type = state.auth.onboarding.subscription;
      // if (status === 'active' || type === 'manual'){
      //   return true
      // }else if( status === 'unverified'){
      //   return false
      // }else{
      //   return status
      // }
    },
    firstSubscription: (state) => {
      let subscription = null;
      if (Object.keys(state.billing.restaurants).length > 0) {
        Object.values(state.billing.restaurants).some(restaurant => {
          if (restaurant && restaurant.subscription) {
            subscription = restaurant.subscription;
            return true;
          }
          return false;
        });
      }
      return subscription;
    },
    mandateGiven: (state) => state.billing.account?.payment_methods.length > 0,
  },

  actions: {
    async createStripeSubscription() {
      return await POST(`accounts/:accountId/restaurants/:restaurantId/subscribe`)
    },

    async getSubscriptions() {
      const response = await GET('subscriptions/tiers')
      this.subscriptions = response.subscriptions
      return response
    },

    async getCredits() {
      const response = await GET(`accounts/:accountId/billing/credits`)
      this.credits = response
      return response
    },

    async getAccountInvoices() {
      try {
        const invoices = await this.getInvoices()
        this.accountInvoices = invoices
        this.accountInvoicesCallComplete = true
        return invoices
      } catch (err) {
        console.error(err)
        throw err
      }
    },

    async getInvoices() {
      const response = await GET(`accounts/:accountId/billing/invoices`)
      return response.invoices
    },

    async getSubscriptionSchedule() {
      return await GET(`dashboard/api/v1/accounts/${this.billing.accountId}/billing/subscription-schedule`)
    },

    async checkBilling() {
      return await GET(`dashboard/api/v1/accounts/${this.billing.accountId}/billing/subscriptions`)
    },

    async updateBilling(newSubscription) {
      return await PUT(`dashboard/api/v1/accounts/${this.billing.accountId}/billing/subscriptions`, {'subscription': newSubscription})
    },

    async cancelBilling() {
      return await DELETE(`dashboard/api/v1/accounts/${this.billing.accountId}/billing/subscriptions`)
    },

    async createRestaurantBilling(customerInfoObj) {
      return await PUT(`dashboard/api/v1/accounts/${this.billing.accountId}/restaurants/${customerInfoObj.restaurantId}/billing`, customerInfoObj)
    },

    async updateRestaurantBillingInfo(customerInfoObj) {
      return await PUT(`dashboard/api/v1/accounts/${this.billing.accountId}/restaurants/${customerInfoObj.restaurantId}/billing-info`, customerInfoObj)
    },

    async getBilling(forceReload = false) {
      if (forceReload || (!this.billing.loaded && !this.billing.loading)) {
        this.billing.loading = true
        try {
          const response = await GET(`accounts/:accountId/billing/methods`)
          this.billing = { 
            ...response, 
            loading: false, 
            loaded: true 
          }
          return response
        } catch (error) {
          this.billing.loading = false
          console.error('Error fetching billing information:', error)
          return null
        }
      }
      return null
    },

    async getRestaurantBilling(restaurantId = null) {
      const response = await GET(`dashboard/api/v1/accounts/${this.billing.accountId}/restaurants/${restaurantId}/billing`)
      this.restaurantBilling = {
        ...this.restaurantBilling,
        [restaurantId]: response
      }
      return response
    },

    async createAccountBilling(customerInfoObj) {
      return await POST(`dashboard/api/v1/accounts/${this.billing.accountId}/billing/methods`, customerInfoObj)
    },

    async updateAccountBillingInfo(customerInfoObj) {
      return await PUT(`dashboard/api/v1/accounts/${this.billing.accountId}/billing/billing-info`, customerInfoObj)
    },

    async setAccountPaymentMethodActive(payload) {
      return await PUT(`dashboard/api/v1/accounts/${this.billing.accountId}/billing/activate-payment-method/${payload.paymentMethodId}`)
    },

    async deletePaymentMethod(paymentMethodId) {
      return await DELETE(`dashboard/api/v1/accounts/${this.billing.accountId}/billing/methods/${paymentMethodId}`)
    },

    async setRestaurantPaymentMethodActive(payload) {
      return await PUT(`dashboard/api/v1/accounts/${this.billing.accountId}/restaurants/${payload.restaurantId}/activate-payment-method/${payload.paymentMethodId}`)
    },

    async getReceiptUrl(chargeId) {
      return await GET(`dashboard/api/v1/accounts/${this.billing.accountId}/billing/receipt/${chargeId}`)
    },
  },
})
