<template>
  <nav class="flex" aria-label="Breadcrumb">
    <ol class="inline-flex items-center space-x-1">
      <li class="inline-flex items-center">
        <a href="/" class="text-sm font-medium text-orange-600">
          {{ restaurantName || 'Loading...' }}
        </a>
      </li>
      <li v-for="(crumb, index) in breadcrumbs" :key="index" class="inline-flex items-center">
        <ChevronRightIcon class="w-4 h-4 text-gray-400" />
        <a
          :href="crumb.path"
          class="ml-1 text-sm font-medium text-gray-700 hover:text-orange-600"
        >
          {{ crumb.name }}
        </a>
      </li>
    </ol>
  </nav>
</template>

<script setup>
import { computed, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { useRestaurantsStore } from '@/stores/restaurants'
import { ChevronRightIcon } from 'lucide-vue-next'

const route = useRoute()
const restaurantsStore = useRestaurantsStore()

const restaurantName = computed(() => {
  return restaurantsStore.currentRestaurant?.name || ''
})

// Watch for changes in currentRestaurant to update breadcrumbs accordingly
watchEffect(() => {
  if (!restaurantsStore.currentRestaurant) {
    console.warn('🔍 [RouteBreadcrumb] currentRestaurant is undefined.')
  }
})

const breadcrumbs = computed(() => {
  const pathArray = route.path.split('/')
  pathArray.shift()

  return pathArray.map((path, index) => {
    return {
      name: getPageName(path),
      path: '/' + pathArray.slice(0, index + 1).join('/')
    }
  })
})

function getPageName(path) {
  const pageNames = {
    'qrs': 'QR Codes',
    // Add more mappings here for other routes
  }
  return pageNames[path] || path.charAt(0).toUpperCase() + path.slice(1)
}
</script>
