/*!
=========================================================
* Orderli Dashboard - v0.1.3
=========================================================
*/

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './routes/starterRouter'
import axios from 'axios'
import { useAuthStore } from '@/stores/auth'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { getIdsFromQueryParams } from '@/plugins/processQueryParams'
import DashboardPlugin from './plugins/dashboard-plugin'

// Styles
import '@/assets/css/nprogress.css'
import '@/assets/index.css'

// Axios setup
axios.defaults.baseURL = import.meta.env.VITE_APP_SERVER_URL

const token = localStorage.getItem('jwt')
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

// Axios interceptor for handling 401 errors
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      const authStore = useAuthStore()
      authStore.resetAuthState()
      router.push('/login')
    }
    return Promise.reject(error)
  }
)

const app = createApp(App)
const pinia = createPinia()

app.use(pinia)
app.use(router)
app.use(DashboardPlugin)

// Initialize auth store and check user status before mounting the app
const authStore = useAuthStore()

authStore.checkUserStatus().catch(() => {
  // If checkUserStatus fails, the user is not authenticated
  authStore.resetAuthState()
}).finally(() => {
  // Process query parameters
  let url = location.href
  let decodedUrl = decodeURIComponent(url)
  decodedUrl = decodedUrl.replace(/&amp;/g, '&')
  let newQueryPath = getIdsFromQueryParams(new URL(decodedUrl).searchParams)
  if (newQueryPath.hasIds) {
    router.replace({path: window.location.pathname, query: newQueryPath.query, hash: window.location.hash}).catch(() => {})
  }

  // Mount the app
  app.mount('#app')
})

// Sentry setup (commented out for now)
// if (import.meta.env.VITE_APP_SENTRY_DNS) {
//   Sentry.init({
//     Vue: app,
//     dsn: import.meta.env.VITE_APP_SENTRY_DNS,
//     integrations: [new Integrations.BrowserTracing()],
//     tracesSampleRate: 1.0,
//   })
// }