<template>
  <div
    ref="headerRoot"
    class="header"
    :class="{ 'is-sticky': isSticky, 'is-condensed': isCondensed }"
    :style="{ top: `${headerTop}px` }"
  >
    <div class="container-fluid">
      <div class="header-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'

export default defineComponent({
  name: 'BaseHeader',

  props: {
    isSticky: {
      type: Boolean,
      default: false,
    },
    isCondensed: {
      type: Boolean,
      default: false,
    },
    headerTop: {
      type: Number,
      required: true,
    },
  },

  setup(props, { expose }) {
    const headerRoot = ref(null)

    expose({ headerRoot })

    return { headerRoot }
  },
})
</script>

<style scoped>
.header {
  width: 100%;
  background-color: white;
  transition: all 0.3s ease;
  height: auto;
}

.header.is-sticky {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header.is-condensed {
  height: 64px;
}
</style>