import { defineStore } from 'pinia'
import { GET, POST, PUT, DELETE } from '../../utils/api'
import { useAuthStore } from '../auth'
import { toast } from 'vue-sonner'

export const useMenuItemsStore = defineStore('menuItems', {
  state: () => ({
    items: [],
    inactiveItems: [],
    itemTypes: null,
    inactiveItemsHaveLoadedValue: false,
    inactiveItemsIsLoadingValue: false,
    showItemModalId: null,
  }),

  getters: {
    inactiveItemsIsLoading: (state) => state.inactiveItemsIsLoadingValue,
    inactiveItemsHaveLoaded: (state) => state.inactiveItemsHaveLoadedValue,
    itemsOfCurrentRestaurant() {
      const authStore = useAuthStore()
      let items = this.items.filter(x => x.restaurant_id === authStore.restaurantId && !x.is_option)
      let inactiveItems = this.inactiveItems.filter(x => x.restaurant_id === authStore.restaurantId && !x.is_option)
      return items.concat(inactiveItems).sort((a, b) => a.id - b.id)
    },
    optionItemsOfCurrentRestaurant() {
      const authStore = useAuthStore()
      return this.items
        .filter(x => x.restaurant_id === authStore.restaurantId && x.is_option)
        .sort((a, b) => a.id - b.id)
    },
    itemsAndOptionItemsOfCurrentRestaurant() {
      return this.itemsOfCurrentRestaurant.concat(this.optionItemsOfCurrentRestaurant)
    },
    itemsMap() {
      return new Map(this.itemsOfCurrentRestaurant.map(element => [element.id, element]))
    },
    optionItemsMap() {
      return new Map(this.optionItemsOfCurrentRestaurant.map(element => [element.id, element]))
    },
    itemsAndOptionsMap() {
      return new Map(this.itemsAndOptionItemsOfCurrentRestaurant.concat(this.inactiveItemsOfCurrentRestaurant).map(element => [element.id, element]))
    },
    itemsAndOptionsPosIdMap() {
      return new Map(this.itemsAndOptionItemsOfCurrentRestaurant.concat(this.inactiveItemsOfCurrentRestaurant).map(element => [element.pos_id, element]))
    },
    inactiveItemsOfCurrentRestaurant() {
      const authStore = useAuthStore()
      return this.inactiveItems
        .filter(x => x.restaurant_id === authStore.restaurantId)
        .sort((a, b) => a.id - b.id)
    },
  },

  actions: {
    async searchInactiveItems(query) {
      this.inactiveItemsIsLoadingValue = true
      try {
        const response = await GET(`accounts/:accountId/menu/:restaurantId/inactive`, { params: { query } })
        this.inactiveItems = response.items
        this.inactiveItemsHaveLoadedValue = true
        return response
      } finally {
        this.inactiveItemsIsLoadingValue = false
      }
    },

    async getInactiveItems() {
      this.inactiveItemsIsLoadingValue = true
      try {
        const response = await GET(`accounts/:accountId/menu/:restaurantId/inactive`)
        this.inactiveItems = response.items
        this.inactiveItemsHaveLoadedValue = true
        return response.items
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.inactiveItemsIsLoadingValue = false
      }
    },

    async getInactiveItemsIfUndefined() {
      if (!this.inactiveItemsHaveLoaded && !this.inactiveItemsIsLoading) {
        return await this.getInactiveItems()
      }
      return this.inactiveItems
    },

    async getItemTypes() {
      const response = await GET(`accounts/:accountId/restaurants/:restaurantId/item-types`)
      this.itemTypes = response.item_types.sort((a, b) => a.id - b.id)
      return response
    },

    async getItemTypesIfUndefined() {
      if (!this.itemTypes) {
        return await this.getItemTypes()
      }
      return 'done'
    },

    async createItemType(itemType) {
      const response = await POST(`accounts/:accountId/restaurants/:restaurantId/item-types`, itemType)
      this.itemTypes.push(response)
      return response
    },

    async updateItemType(itemType) {
      return await PUT(`accounts/:accountId/restaurants/:restaurantId/item-types`, { item_types: [itemType] })
    },

    async newItem(item) {
      const response = await POST('accounts/:accountId/menu/items', item)
      toast.success(`Item ${item.name} Created`)
      this.items.push(response.item)
      return response
    },

    async updateItem(itemArray) {
      const response = await PUT('accounts/:accountId/menu/items', { items: itemArray })
      const itemNames = itemArray.map(item => this.itemsMap.get(item.id)?.name).join(', ')
      const notificationTitle = itemArray.length > 1 ? `Items ${itemNames} updated` : `Item ${itemNames} updated`
      toast.success(notificationTitle)
      itemArray.forEach(updatedItem => {
        let foundIndex = this.items.findIndex(x => x.id === updatedItem.id)
        if (foundIndex === -1) {
          foundIndex = this.inactiveItems.findIndex(x => x.id === updatedItem.id)
          if (foundIndex !== -1) {
            Object.assign(this.inactiveItems[foundIndex], updatedItem)
          }
        } else {
          Object.assign(this.items[foundIndex], updatedItem)
          // Update cross sell recommendations to active/inactive
          if (this.items[foundIndex].recommendations) {
            this.items[foundIndex].recommendations.forEach(recommendation => {
              recommendation.active = updatedItem.recommendations.includes(recommendation.item_id)
            })
          }
        }
      })
      return response
    },

    async updateInactiveItem(itemsArray) {
      const response = await PUT('accounts/:accountId/menu/items', { items: itemsArray })
      const itemNames = itemsArray.map(item => this.itemsAndOptionsMap.get(item.id)?.name).join(', ')
      const notificationTitle = itemsArray.length > 1 ? `Inactive items ${itemNames} updated` : `Inactive item ${itemNames} updated`
      toast.success(notificationTitle)
      itemsArray.forEach(item => {
        let foundIndex = this.inactiveItems.findIndex(x => x.id === item.id)
        if (foundIndex !== -1) {
          Object.assign(this.inactiveItems[foundIndex], item)
          if (item.active === true) {
            this.items.push(this.inactiveItems[foundIndex])
            this.inactiveItems.splice(foundIndex, 1)
          }
        } else {
          this.updateItem([item])
        }
      })
      return response
    },

    async deleteItem(itemId) {
      const response = await DELETE(`accounts/:accountId/menu/items/${itemId}`)
      const itemName = this.itemsMap.get(itemId)?.name
      toast.success(`Item ${itemName} Deleted`)
      this.items = this.items.filter(item => item.id !== itemId)
      return response
    },

    async deleteInactiveItem(item) {
      const response = await DELETE(`accounts/:accountId/menu/items/${item.id}`)
      const itemName = this.itemsAndOptionsMap.get(item.id)?.name
      toast.success(`Inactive item ${itemName} Deleted`)
      this.inactiveItems = this.inactiveItems.filter(inactiveItem => inactiveItem.id !== item.id)
      return response
    },

    async activateItem(itemId) {
      const response = await PUT(`accounts/:accountId/menu/items/${itemId}/activate`)
      const item = this.inactiveItems.find(item => item.id === itemId)
      if (item) {
        this.items.push(item)
        this.inactiveItems = this.inactiveItems.filter(item => item.id !== itemId)
      }
      return response
    },

    async deactivateItem(itemId) {
      const response = await PUT(`accounts/:accountId/menu/items/${itemId}/deactivate`)
      const item = this.items.find(item => item.id === itemId)
      if (item) {
        this.inactiveItems.push(item)
        this.items = this.items.filter(item => item.id !== itemId)
      }
      return response
    },

    async searchUntappd(query) {
      return await POST(`accounts/:accountId/menu/:restaurantId/search-untappd`, { query })
    },

    async getVatPercentages() {
      return await GET(`accounts/:accountId/menu/:restaurantId/vat-percentages`)
    },

    async updateVatPercentages(obj) {
      return await PUT(`accounts/:accountId/menu/:restaurantId/vat-percentages`, obj)
    },

    setItemModal(payload) {
      this.showItemModalId = payload
    },
  },
})
